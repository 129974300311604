import React from 'react'
import { themeStyles, colors, presets } from '../utils/theme'
import VentureBeat from '../img/VentureBeat.svg'
import Forbes from '../img/Forbes.svg'
import BusinessInsider from '../img/BusinessInsider.svg'
import Bloomberg from '../img/Bloomberg.svg'

const styles = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    color: colors.anotherGrey,
    padding: '20px 0',
    borderTop: `1px solid ${colors.anotherGrey}`,
    borderBottom: `1px solid ${colors.anotherGrey}`,
    minWidth: '280px',
    [presets.Tablet]: {
      flexDirection: 'row',
      alignSelf: 'stretch',
    }
  },
  item: {
    maxHeight: '30px',
    marginTop: '10px',
    [presets.Tablet]: {
      margin: '5px 10px',
    }
  },
  text: {
    fontSize: '14px',
    marginBottom: '10px',
    [presets.Tablet]: {
      margin: 0,
    }
  }
}

const FeaturedIn = ({ containerCss }) => {
  return (
    <div css={[styles.contentContainer, containerCss]}>
      <div css={styles.row}>
        <div css={styles.text}>
          Featured In:
        </div>
        <img alt='Bloomberg' src={Bloomberg} css={styles.item} />
        <img alt='VentureBeat' src={VentureBeat} css={[styles.item, {maxHeight: '25px'}]} />
        <img alt='Forbes' src={Forbes} css={[styles.item, {maxHeight: '27px'}]} />
        <img alt='BusinessInsider' src={BusinessInsider} css={styles.item} />
      </div>
    </div>
  )
}

export default FeaturedIn